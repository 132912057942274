import { Pipe, PipeTransform } from '@angular/core';
import { addMilliseconds, format, startOfDay } from 'date-fns';

@Pipe({
  standalone: true,
  pure: true,
  name: 'time',
})
export class TimePipe implements PipeTransform {
  transform(value: number, formatString: string = 'HH:mm:ss'): string {
    // console.log(">>> Time: value", value);
    if (!value) return '00:00:00';
    const startDate = startOfDay(new Date());
    const resultDate = addMilliseconds(startDate, value);
    return format(resultDate, formatString);
  }
}
